body {
    margin: 2rem;
}

.box {
    border-radius: 30vw;
    margin-top: 1rem;
    background-color: #38B6FF;
    border: 0.15vw solid #7ED957;
    text-decoration: none !important;
    transition: linear all 0.4s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Box shadow */
.box:hover {
    box-shadow: 1px 1px 10px 0px #7ED957;
}