.link {
    text-decoration: none !important;
    font-size: 2.5vw;
}

.email {
    color: gold;
}

.email:hover {
    color: yellow;
}

.Instagram {
    color: purple;
}

.Instagram:hover {
    color: rgb(187, 107, 187);
}

.GroupMe {
    color: blue;
}

.GroupMe:hover {
    color: cyan;
}

.contentContainer {
    min-height: 90%;
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
}